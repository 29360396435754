@import 'styles/variables.scss';
@import 'styles/functions.scss';

.dotsWrap {
  position: static;
}

.slide {
  height: rem(560px);
}

.slideImage {
  @media (min-width: $screens-lg) {
    height: rem(400px);
  }
}
