@import 'styles/variables.scss';

.treatmentsCarousel {
  &:before,
  &:after {
    content: ' ';
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: all 0.2s linear;
    width: 180px;
    z-index: 9;
  }
}

.carouselArrowLeft {
  left: 1px;
}
.carouselArrowRight {
  right: calc(4% + 1px);
}

@media (min-width: $screens-md) {
  .showBefore:before,
  .showAfter:after {
    opacity: 1;
  }

  .showBefore::before {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 34%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#001e5799',GradientType=1 );
  }

  .showAfter:after {
    right: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#ffffff',GradientType=1 );
  }

  .showBeforeSecondaryBright:before,
  .showAfterSecondaryBright:after {
    opacity: 1;
  }

  .showBeforeSecondaryBright::before {
    background: linear-gradient(
      to right,
      rgba(255, 252, 247, 1) 34%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffcf7', endColorstr='#001e5799',GradientType=1 );
  }

  .showAfterSecondaryBright:after {
    right: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 252, 247, 1) 50%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#fffcf7',GradientType=1 );
  }
}
