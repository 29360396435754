@import 'styles/variables.scss';

.blob {
  margin-block: var(--spacing-12);
  margin-left: calc(var(--spacing-20) * -1);
  position: absolute;

  @media (min-width: $screens-md) {
    margin-left: var(--spacing-32);
  }

  @media (min-width: $screens-lg) {
    margin-top: var(--spacing-32);
  }
}

.carouselItem {
  @media (min-width: $screens-md) {
    padding-left: var(--spacing-12);
    padding-bottom: 0;
  }
}

.headline {
  font-size: var(--text-4xl-size);
  line-height: var(--line-height-tight);

  @media (min-width: $screens-lg) {
    font-size: var(--text-6xl-size);
    line-height: var(--line-height-none);
  }
}

.hero {
  margin-inline: auto;
  max-width: $screens-xl;
  padding: var(--spacing-6) var(--spacing-5);

  @media (min-width: $screens-lg) {
    background-image: url('/v2/images/bg-hp-hero.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: var(--spacing-12) var(--spacing-4);
  }
}

.heroCheck {
  display: inline-flex;
  margin-right: var(--spacing-2);

  path {
    fill: var(--color-secondary);

    @media (min-width: $screens-lg) {
      fill: var(--color-navy);
    }
  }
}

.heroGrid {
  @media (min-width: $screens-lg) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    overflow: hidden;
  }
}

.heroLeftColumn {
  @media (min-width: $screens-md) {
    margin-inline: var(--spacing-4);
  }

  @media (min-width: $screens-lg) {
    margin-inline: 0;
    padding-top: var(--spacing-14);
  }
}

.heroRightColumn {
  @media (min-width: $screens-lg) {
    margin-left: calc(var(--spacing-48) * -1);
  }

  @media (min-width: $screens-xl) {
    margin-left: calc(var(--spacing-32) * -1);
  }
}

a.qunosuiteButton {
  background-color: var(--color-secondary);
  border-radius: 0.25rem;
  color: var(--color-siam);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-xl-size);
  margin-inline: var(--spacing-3);
  padding: var(--spacing-3) var(--spacing-6);
  text-decoration: none;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;

  @media (min-width: $screens-lg) {
    margin-inline: var(--spacing-6);
  }

  &:hover {
    background-color: var(--color-grandis);
  }
}

.qunosuiteDesktop {
  display: none;
  background-color: var(--color-secondary-darker);
  padding-bottom: var(--spacing-8);
  padding-top: var(--spacing-6);

  @media (min-width: $screens-lg) {
    display: block;
  }

  @media (min-width: $screens-xl) {
    padding-block: var(--spacing-8);
  }
}

.qunosuiteDesktopActions {
  margin-left: auto;
}

.qunosuiteDesktopInner {
  font-size: var(--text-base-size);
  margin-inline: auto;
  max-width: $screens-xl;
  padding-inline: var(--spacing-5);
  text-align: center;

  @media (min-width: $screens-xl) {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    text-align: right;
  }
}

a.qunosuiteDesktopPress {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-6);
  text-decoration: none;
}

.qunosuiteDesktopText {
  margin-bottom: var(--spacing-6);

  @media (min-width: $screens-xl) {
    margin-bottom: 0;
  }

  span {
    font-weight: 700;
  }
}

.qunosuiteMobile {
  background-color: var(--color-secondary-darker);
  padding: var(--spacing-6) var(--spacing-5) var(--spacing-4);

  @media (min-width: $screens-lg) {
    display: none;
  }
}

.qunosuiteMobileActions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.qunosuiteMobileInner {
  font-size: var(--text-lg-size);
  margin-inline: auto;
  max-width: $screens-xl;
  padding-inline: var(--spacing-5);
  text-align: center;
}

a.qunosuiteMobilePress {
  margin-inline: var(--spacing-3);
  margin-bottom: var(--spacing-4);
  text-decoration: none;

  @media (min-width: $screens-sm) {
    margin-inline: var(--spacing-6);
  }
}

.qunosuiteMobileText {
  margin-bottom: var(--spacing-6);

  span {
    font-weight: 700;
  }
}

.text {
  margin-bottom: var(--spacing-8);

  @media (min-width: $screens-md) {
    margin-bottom: var(--spacing-12);
  }
}

.usp {
  display: flex;
  align-items: center;
  margin-block: var(--spacing-2);
}
